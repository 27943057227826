<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="用户 " prop="userId">
                <a-input v-model="queryParam.nickName" placeholder="请输入用户 " allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="审核状态" prop="sex">
                <a-select v-model="queryParam.approveStatus" placeholder="请选择" style="width: 100%" allow-clear>
                  <a-select-option :value="0">待审核</a-select-option>
                  <a-select-option :value="1">已审核</a-select-option>
                  <a-select-option :value="2">已驳回</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="小区 " prop="villageId">
                <a-input v-model="queryParam.village" placeholder="请输入小区 " allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="楼栋" prop="buildingId">
                  <a-input v-model="queryParam.building" placeholder="请输入楼栋" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="单元" prop="unitId">
                  <a-input v-model="queryParam.unit" placeholder="请输入单元" allow-clear/>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="房间" prop="roomId">
                  <a-input v-model="queryParam.room" placeholder="请输入房间" allow-clear/>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">

        <a-button type="primary" :disabled="multiple" @click="handleApprove"  >
          <a-icon type="edit" />批量审核通过
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete"  >
          <a-icon type="delete" />删除
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <approve-modal ref='approveModal' @ok="getList" @close="getList"></approve-modal>
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

        <span slot="photos" slot-scope="text, record">

           <template v-if="record.imgs">
            <img-preview v-for="item in record.imgs.split(',')" :src="item" :size="60"
                         style="margin-right: 8px;" />
          </template>
          <template v-else>未上传</template>
        </span>
        <span slot="operation" slot-scope="text, record">
          <a v-if='record.approveStatus == 0' @click="$refs.approveModal.audit(record)"  >
            <a-icon type="edit" />审核
          </a>

          <a-divider type="vertical"   />
          <a @click="handleDelete(record)"  >
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageJoinApply,listJoinApply,batchApprove, delJoinApply } from '@/api/village/joinApply'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import ApproveModal from './modules/ApproveModal.vue'
import Template from '@/views/sms/template/index.vue'
import ImgPreview from '@/components/crud/table/ImgPreview.vue'

export default {
  name: 'JoinApply',
  components: {
    ImgPreview, Template,
    ApproveModal,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        nickName: null,
        village: null,
        building: null,
        unit: null,
        room: null,
        type: null,
        imgs: null,
        approveStatus: null,
        approveContent: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          ellipsis: true,
          width: 50,
          align: 'center',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '用户',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '小区',
          dataIndex: 'village',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '认证房源',
          dataIndex: 'building',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            return r.building + r.unit + r.room
          }
        },
        // {
        //   title: '单元',
        //   dataIndex: 'unit',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '房间',
        //   dataIndex: 'room',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '认证类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '业主'
            }else if(t == 1) {
              return '租客'
            }
          }
        },
        {
          title: '认证图片',
          dataIndex: 'imgs',
          align: 'center',
          scopedSlots: { customRender: 'photos' },
        },
        {
          title: '审核状态',
          dataIndex: 'approveStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (t, r, index) {
            if(t == 0) {
              return '待审核'
            }else if(t == 1) {
              return '审核通过'
            }else if(t == 2) {
              return '审核驳回'
            }
          }
        },
        {
          title: '审核失败原因',
          dataIndex: 'approveContent',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: '8%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询用户入驻申请列表 */
    getList () {
      this.loading = true
     pageJoinApply(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        villageId: undefined,
        buildingId: undefined,
        unitId: undefined,
        roomId: undefined,
        type: undefined,
        imgs: undefined,
        approveStatus: undefined,
        approveContent: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delJoinApply(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    handleApprove (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认审核通过所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return batchApprove(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '成功',
                3
              )
            })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('user/user-join-apply/export', {
            ...that.queryParam
          }, `用户入驻申请_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
