<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户id，外键{user.id}" />
      </a-form-model-item>
      <a-form-model-item label="小区ID，zb_village" prop="villageId" >
        <a-input v-model="form.villageId" placeholder="请输入小区ID，zb_village" />
      </a-form-model-item>
      <a-form-model-item label="楼栋ID，zb_building" prop="buildingId" >
        <a-input v-model="form.buildingId" placeholder="请输入楼栋ID，zb_building" />
      </a-form-model-item>
      <a-form-model-item label="单元ID，zb_unit" prop="unitId" >
        <a-input v-model="form.unitId" placeholder="请输入单元ID，zb_unit" />
      </a-form-model-item>
      <a-form-model-item label="房间ID，zb_room" prop="roomId" >
        <a-input v-model="form.roomId" placeholder="请输入房间ID，zb_room" />
      </a-form-model-item>
      <a-form-model-item label="认证类型0业主1租客" prop="type" >
      </a-form-model-item>
      <a-form-model-item label="认证图片" prop="imgs" >
        <a-input v-model="form.imgs" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="审核状态0待审核1审核通过2审核驳回" prop="approveStatus" >
      </a-form-model-item>
      <a-form-model-item label="审核失败原因" prop="approveContent" >
        <editor v-model="form.approveContent" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getJoinApply, addJoinApply, updateJoinApply } from '@/api/village/joinApply'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        villageId: null,

        buildingId: null,

        unitId: null,

        roomId: null,

        type: null,

        imgs: null,

        approveStatus: 0,

        approveContent: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户id，外键{user.id}不能为空', trigger: 'blur' }
        ],
        villageId: [
          { required: true, message: '小区ID，zb_village不能为空', trigger: 'blur' }
        ],
        buildingId: [
          { required: true, message: '楼栋ID，zb_building不能为空', trigger: 'blur' }
        ],
        unitId: [
          { required: true, message: '单元ID，zb_unit不能为空', trigger: 'blur' }
        ],
        roomId: [
          { required: true, message: '房间ID，zb_room不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        villageId: null,
        buildingId: null,
        unitId: null,
        roomId: null,
        type: null,
        imgs: null,
        approveStatus: 0,
        approveContent: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getJoinApply({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateJoinApply(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addJoinApply(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
